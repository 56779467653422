<template>
<div>
    <router-view/>
</div>
</template>

<script>
export default {
  name: 'HomelyRemedies',
  data () {
    return {
    }
  },
}
</script>

<style lang="scss">
</style>